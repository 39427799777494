export const LOGIT_INTERCEPTS = {
    1: -0.5476120221759906,
    2: -2.382096113449031,
    3: -3.4027336989563057,
    4: -1.475469860612377,
    5: -1.8656363369883529,
    6: -2.4885049281139273,
    7: -2.319322919473329,
    8: -2.9332947538819396,
    9: -3.908922425559676,
    10: -3.486424576980363,
    11: -3.0727087472006107,
    12: -2.3067278562753244,
    13: -1.6204534336146785,
    14: -2.5655149861178708,
    15: 0.0,
    16: -1.2637425453520927,
    17: -1.1115358324147542,
    18: -2.242555913137936,
    19: -0.5726979978640294,
    20: -1.6044827599627214,
};
export const LOGIT_PFA = {
    1: 0.1482339732670437,
    2: 0.24983009966171785,
    3: 0.2314622947153627,
    4: 0.1856940634575732,
    5: 0.26325012697479294,
    6: 0.28592365798579217,
    7: 0.23943939246064308,
    8: 0.28716445109344446,
    9: 0.3676912134269827,
    10: 0.2034325860538424,
    11: 0.15753216863332312,
    12: 0.21469820621566085,
    13: 0.21619964770530675,
    14: 0.2516440910264213,
    15: 0.2768417272155105,
    16: 0.18724829263561432,
    17: 0.16146027440989136,
    18: 0.18331720390477943,
    19: 0.27036990562184277,
    20: 0.27825200621314483,
};
export const LOGIT_NFA = {
    1: 0.4508597309234735,
    2: 0.31379424621395885,
    3: 0.2884299199509764,
    4: 0.533486498401133,
    5: 0.35803419386618474,
    6: 0.38113933270833344,
    7: 0.2707649326042125,
    8: 0.29033321700992143,
    9: 0.22526653326969298,
    10: 0.39139030777269834,
    11: 0.42892429735233734,
    12: 0.45697048130582285,
    13: 0.48079127149791073,
    14: 0.3391552895397265,
    15: 0.492999500152144,
    16: 0.42115565439312863,
    17: 0.4619888007080423,
    18: 0.4605879766094817,
    19: 0.4158183474272363,
    20: 0.3664927402443128,
};
export const LOGIT_IS_POS2 = {
    1: 0.012310590971261304,
    2: 0.08858958939593693,
    3: 0.19520339268306663,
    4: 0.2993884753503697,
    5: 0.24266340453355822,
    6: 0.2518210716399733,
    7: 0.33934854430287054,
    8: -0.03211794146440487,
    9: 0.18660779158996194,
    10: 0.41754751352815583,
    11: 0.5393886745570008,
    12: 0.32068580647942846,
    13: 0.3218080087271035,
    14: 0.24283281553037256,
    15: 0.1306979575326713,
    16: 0.045662619157293305,
    17: 0.07781472802185418,
    18: 0.3710736768988859,
    19: 0.2002871885385842,
    20: 0.09781774621666868,
};
export const LOGIT_IS_POS3 = {
    1: 0.2928146334413342,
    2: 0.4646548744191676,
    3: 0.5576238372223293,
    4: 0.5962948262731624,
    5: 0.5525665148138736,
    6: 0.39398560614613487,
    7: 0.6057239936383454,
    8: 0.2029590269332418,
    9: 0.5143837105774919,
    10: 0.8316612544682042,
    11: 0.5867788706436802,
    12: 0.5600605336474364,
    13: 0.5142055279572093,
    14: 0.4436345046602271,
    15: 0.399928385509489,
    16: 0.31855379881666274,
    17: 0.23603779462344743,
    18: 0.668872309809387,
    19: 0.5220793091998849,
    20: 0.5550478149824248,
};
export const LOGIT_IS_POS4 = {
    1: 0.4591504591447953,
    2: 0.6948250031809887,
    3: 0.7901513412541377,
    4: 0.8954546900202422,
    5: 0.7905058337161098,
    6: 0.6920280904638357,
    7: 0.8217694181164641,
    8: 0.5959982889423611,
    9: 0.9976556091556895,
    10: 1.0499226751358506,
    11: 1.0656417422674627,
    12: 0.9877020343159111,
    13: 0.9043022730823018,
    14: 0.6901957721734495,
    15: 0.5160156711145106,
    16: 0.671062330486613,
    17: 0.5406271704949204,
    18: 0.9567011796394034,
    19: 0.7464574652551599,
    20: 0.8461409387052513,
};
